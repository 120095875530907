<template>
  <form @submit.prevent="cancelContract">
    <modal-card
      :title="`${isActive ? 'Cancel contract' : 'Terminate contract'}`"
      cancel-text="Close"
    >
      <loading v-if="loading" />
      <div v-else>
        <b-field v-if="isActive" label="Reason*">
          <textarea-autosize
            v-model="form.reason"
            :max-height="200"
            :disabled="processing"
            placeholder="Tell us why you're cancelling..."
            class="textarea"
            rows="3"
            autosize
          />
        </b-field>

        <b-checkbox
          v-model="form.terminate"
          :disabled="processing || terminateDisabled"
          @input="onTerminateChange"
        >
          Terminate service with immediate effect &amp; prevent use of any
          remaining term.
        </b-checkbox>

        <section v-if="form.terminate">
          <hr />
          <b-field>
            <b-radio
              v-model="form.refundType"
              :native-value="null"
              :disabled="processing"
            >
              Issue no refund
            </b-radio>
          </b-field>
          <b-field>
            <b-radio
              v-model="form.refundType"
              :native-value="'manual'"
              :disabled="processing"
              >Refund manually (via Stripe)
            </b-radio>
          </b-field>
          <b-field>
            <b-radio
              v-model="form.refundType"
              :native-value="'credit'"
              :disabled="processing"
              >Refund as account credit
            </b-radio>
          </b-field>
        </section>

        <div
          v-if="['manual', 'credit'].includes(form.refundType)"
          class="has-margin-top-100"
        >
          <b-field label="Refund amount*">
            <b-field>
              <b-input
                ref="amount"
                v-model.number="form.amount"
                expanded
                :disabled="processing"
                type="number"
                step="0.01"
                min="0"
              />
              <p class="control">
                <a class="button is-static">
                  {{ $_.get(contract, "price.currency", "").toUpperCase() }}
                </a>
              </p>
            </b-field>
          </b-field>
          <p class="has-text-grey is-size-7">
            <strong>Important:</strong>
            Please consider/check the use of discounts and credits applied to
            past invoices, as to avoid over-payment.
          </p>
        </div>
      </div>
      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="loading || processing || !validForm"
        type="submit"
        class="button is-danger"
      >
        {{ isActive ? "Cancel contract" : "Terminate contract" }}
      </button>
    </modal-card>
  </form>
</template>

<script>
import { enums as CS } from "@/data/contract";
export default {
  name: "CancelModal",
  props: {
    contractId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      processing: false,
      terminateDisabled: false,
      form: {
        contractId: this.contractId,
        reason: "",
        terminate: false,
        refundType: null,
        amount: 0
      }
    };
  },
  computed: {
    contract() {
      return this.$store.getters["billing/contract"](this.contractId) || {};
    },
    isActive() {
      return this.contract.status > CS.CANCELLED;
    },
    validForm() {
      if (this.isActive && this.$_.isEmpty(this.form.reason)) return false;
      if (
        this.form.terminate &&
        ["manual", "credit"].includes(this.form.refundType) &&
        this.form.amount <= 0
      )
        return false;
      return true;
    }
  },
  created() {
    this.observeContract().finally(() => {
      this.loading = false;
      // If already cancelled allow only termination
      this.form.terminate = this.contract.status === CS.CANCELLED;
      this.terminateDisabled = this.contract.status === CS.CANCELLED;
    });
  },
  beforeDestroy() {
    this.unobserveContract();
  },
  methods: {
    observeContract() {
      return this.$store.dispatch("billing/observeContract", {
        siteId: this.contractId
      });
    },
    unobserveContract() {
      this.$store.dispatch("billing/unobserveContract", {
        siteId: this.contractId
      });
    },
    onTerminateChange(terminate) {
      if (!terminate) this.form.refundType = null;
    },
    cancelContract() {
      this.processing = true;
      return this.$store
        .dispatch("billing/cancelContract", this.form)
        .then(result => {
          this.$emit("updated");
          this.$emit("close");
          this.$toast.open({
            message: `${result}`
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
::v-deep .modal-card-head {
  background-color: $danger !important;
}
</style>
